import React from "react"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import AboutSlider from "../components/Slider/Slider"

const About = () => (
  <Layout>
    <Seo title="About" />
    <h1>About</h1>
    <AboutSlider />
  </Layout>
)

export default About
