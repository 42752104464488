import React from "react"
import Slider from "react-slick"

import "./Slider.css"
import "slick-carousel/slick/slick.css"

const settings = {
  dots: false,
  infinite: true,
  vertical: true,
  fade: true,
  autoplay: true,
  autoplaySpeed: 5000,
};

const AboutSlider = () => (
  <Slider {...settings}>
    {Array.from(Array(6).keys()).map(val => (
      <img
        src={`https://eco.kfc.pl/static/img/jpg/slider_${val + 1}.jpg`}
        key={`slider_${val + 1}`}
        alt={`slider_${val + 1}`}
      />
    ))}
  </Slider>
)

export default AboutSlider
